import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { alpha, colors, s } from '../../../style'

const Table = () => {
  const query = useStaticQuery(graphql`
    query {
      #_1A: file(absolutePath: { regex: "/content/visual/b1_a.pdf/" }#) {
      #  publicURL
      #}
      #_1B: file(absolutePath: { regex: "/content/visual/b1_b.pdf/" }#) {
      #  publicURL
      #}
      _3A: file(absolutePath: { regex: "/content/visual/b3_a.pdf/" }) {
        publicURL
      }
      _3B: file(absolutePath: { regex: "/content/visual/b3_b.pdf/" }) {
        publicURL
      }
      # _4A: file(absolutePath: { regex: "/content/visual/b4_a.pdf/" }) {
      #   publicURL
      # }
      # _4B: file(absolutePath: { regex: "/content/visual/b4_b.pdf/" }) {
      #   publicURL
      # }
      # _5A: file(absolutePath: { regex: "/content/visual/b5_a.pdf/" }) {
      #  publicURL
      # }
      # _5B: file(absolutePath: { regex: "/content/visual/b5_b.pdf/" }) {
      #  publicURL
      # }
      #_6A: file(absolutePath: { regex: "/content/visual/b6_a.pdf/" }) {
      #  publicURL
      # }
      #_6B: file(absolutePath: { regex: "/content/visual/b6_b.pdf/" }) {
      #  publicURL
      #}
      #_10A: file(absolutePath: { regex: "/content/visual/b10_a.pdf/#" }) {
      #  publicURL
      #}
      #_10B: file(absolutePath: { regex: "/content/visual/b10_b.pdf/#" }) {
      #  publicURL
      #}
    }
  `)

  const data = [
    {
      index: '1A',
      residence: 'Sycylia',
      apartment: 'Palermo',
      rooms: 4,
      area: 177.43,
      garden: 312.5,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '1B',
      residence: 'Sycylia',
      apartment: 'Katania',
      rooms: 4,
      area: 177.43,
      garden: 312.5,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '3A',
      residence: 'Roma',
      apartment: null,
      rooms: 4,
      area: 177.43,
      garden: 312.5,
      pricePerMeter: 1,
      price: 1,
      isSold: false,
      isBooked: false,
      isClosed: false,
    },
    {
      index: '3B',
      residence: 'Roma',
      apartment: null,
      rooms: 4,
      area: 177.43,
      garden: 312.5,
      pricePerMeter: 1,
      price: 1,
      isSold: false,
      isBooked: false,
      isClosed: false,
    },
    {
      index: '4A',
      residence: 'Apulia',
      apartment: 'Bari',
      rooms: 4,
      area: 165.52,
      garden: 309,
      pricePerMeter: null,
      price: null,
      isSold: true,
      isBooked: false,
    },
    {
      index: '4B',
      residence: 'Apulia',
      apartment: 'Lecce',
      rooms: 4,
      area: 155.03,
      garden: 309,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '5A',
      residence: 'Toskania',
      apartment: 'Florencja',
      rooms: 4,
      area: 139.18,
      garden: 285,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '5B',
      residence: 'Toskania',
      apartment: 'Siena',
      rooms: 4,
      area: 139.18,
      garden: 285,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '6A',
      residence: 'Sardynia',
      apartment: 'Cagliari',
      rooms: 4,
      area: 141.92,
      garden: 290.5,
      pricePerMeter: 16344,
      price: null,
      isSold: true,
    },
    {
      index: '6B',
      residence: 'Sardynia',
      apartment: 'Cervo',
      rooms: 4,
      area: 150.36,
      garden: 290.5,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '8',
      residence: 'Verona',
      apartment: null,
      rooms: null,
      area: null,
      garden: null,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '10A',
      residence: 'Kampania',
      apartment: null,
      rooms: 4,
      area: 115.8,
      garden: 325,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
    {
      index: '10B',
      residence: 'Kampania',
      apartment: null,
      rooms: 4,
      area: 115.8,
      garden: 325,
      pricePerMeter: null,
      price: null,
      isSold: true,
    },
  ]

  const spacePrice = (price) =>
    price
      .toString()
      .split('')
      .reverse()
      .map((e, id) => ((id + 1) % 3 === 0 ? ' ' + e : e))
      .reverse()
      .join('')

  return (
    <div css={sOuter}>
      <span css={{ margin: '0 1rem 1rem', opacity: 0.5 }}>
        Aktualizacja: <strong>{new Date().toISOString().slice(0, 10)}</strong>
      </span>
      <div css={sTableHead}>
        <div css={[sCell, size(14, 6)]}>Numer</div>
        <div css={[sCell, size(0, 10), noMobile]}>Willa</div>
        <div css={[sCell, size(0, 10), noMobile]}>Lokal</div>
        <div css={[sCell, size(0, 6), noMobile]}>Pokoje</div>
        <div css={[sCell, size(20, 13)]}>Dom</div>
        <div css={[sCell, size(13, 13)]}>Działka</div>
        {/* <div css={[sCell, size(0, 13), noMobile]}>Cena /m2</div> */}
        <div css={[sCell, size(20, 13)]}>Cena</div>
        <div css={[sCell, size(15, 6)]}>Karta</div>
        <div css={[sCell, size(18, 10)]}>Status</div>
      </div>
      {data.map(
        (
          {
            index,
            residence,
            apartment,
            rooms,
            area,
            garden,
            pricePerMeter,
            price,
            isSold,
            isBooked,
            isClosed,
          },
          id
        ) => (
          <div css={[sRow, id % 2 === 0 && sOdd]} key={id}>
            <div css={[sCell2, size(14, 6)]}>
              <div css={sKey}>Numer</div>
              {index}
            </div>
            <div css={[sCell2, size(0, 10), noMobile]}>
              <div css={sKey}>Willa</div>
              {residence}
            </div>
            <div css={[sCell2, size(0, 10), noMobile]}>
              <div css={sKey}>Lokal</div>
              <strong>{apartment}</strong>
            </div>
            <div css={[sCell2, size(0, 6), noMobile]}>
              <div css={sKey}>Pokoje</div>
              {rooms}
            </div>
            <div css={[sCell2, size(20, 13)]}>
              <div css={sKey}>Dom</div>
              {area && (
                <div>
                  {area} <span css={sUnit}>m2</span>
                </div>
              )}
            </div>
            <div css={[sCell2, size(13, 13)]}>
              <div css={sKey}>Działka</div>
              {garden && (
                <div>
                  {garden} <span css={sUnit}>m2</span>
                </div>
              )}
            </div>
            {/* <div css={[sCell2, size(0, 13), noMobile]}>
              <div css={sKey}>Cena /m2</div>
              {pricePerMeter ? (
                <div>
                  {spacePrice(pricePerMeter)} <span css={sUnit}>PLN</span>
                </div>
              ) : (
                ''
              )}
            </div> */}
            <div css={[sCell2, size(20, 13)]}>
              <div css={sKey}>Cena</div>
              {price ? (
                <div>
                  <a
                    href="tel:+48513185469"
                    id={`call_button_id_table${id}}`}
                    css={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}>
                    Zapytaj
                    <span css={{ [s.xs]: { display: 'none' } }}>
                      &nbsp;o cenę
                    </span>
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
            <div css={[sCell2, size(15, 6)]}>
              <div css={sKey}>Karta Lokalu</div>
              {query[`_${index}`] && (
                <Link
                  to={`${query[`_${index}`].publicURL}`}
                  css={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}>
                  PDF
                </Link>
              )}
            </div>
            <div css={[sCell2, size(18, 10)]}>
              {isSold ? (
                <div css={sSold(isSold)}>Sprzedane</div>
              ) : isClosed ? (
                <div
                  css={[
                    sSold(true),
                    { backgroundColor: '#000000'.concat(alpha[50]) },
                  ]}>
                  Niedostępne
                </div>
              ) : (
                <a
                  css={sBooked(isBooked)}
                  href="mailto:biuro@palermoinvestment.pl">
                  {isBooked ? 'Rezerwacja' : 'Dostępne'}
                </a>
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

const noMobile = {
  [s.sm_down]: { display: 'none' },
}

const sKey = {
  display: 'none',
  fontSize: '0.75rem',
  opacity: 0.5,
}

const size = (xs, md) => ({
  maxWidth: xs + '%',
  flexBasis: xs + '%',
  [s.md]: {
    maxWidth: md + '%',
    flexBasis: md + '%',
  },
})

const sCell = {
  display: 'flex',
  padding: '1rem 1rem',
  opacity: 0.5,
  alignItems: 'flex-end',
  [s.sm_down]: {
    padding: '1rem 0',
    textAlign: 'center',
    fontSize: '0.75rem',
    justifyContent: 'center',
  },
}

const sCell2 = {
  padding: '1rem 1rem',
  fontSize: '0.75rem',
  fontWeight: 'normal',
  opacity: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // [s.sm_down]: {
  //   padding: '1rem 2rem',
  //   fontSize: '0.875rem',
  // },
  [s.sm_down]: {
    padding: '1rem 0',
    textAlign: 'center',
    justifyContent: 'center',
  },
}

const sUnit = {
  opacity: 0.5,
  fontSize: '0.675rem',
  paddingLeft: '0.125rem',
  [s.xs]: {
    // fontSize: '0.875rem',
    display: 'none',
  },
}

const sOuter = {
  display: 'flex',
  width: '100%',
  marginTop: '4rem',
  flexDirection: 'column',
  fontSize: '0.875rem',
}

const sTableHead = {
  display: 'flex',
  alignItems: 'center',
  justifyContend: 'space-between',
  // [s.sm_down]: {
  //   display: 'none',
  // },
}

const sRow = {
  display: 'flex',
  alignItems: 'center',
  justifyContend: 'space-between',
  // [s.xs]: {
  //   // flexWrap: 'wrap',
  //   padding: '1rem 0',
  // },
}

const sOdd = {
  backgroundColor: colors.brand.concat(alpha[16]),
}

const sSold = (sold) => ({
  transition: 'filter 0.3s ease-out',
  textAlign: 'center',
  padding: '0.5rem 0',
  width: '100%',
  color: 'white',
  fontWeight: 'bold',
  cursor: sold ? 'not-allowed' : 'pointer',
  backgroundColor: sold ? '#d64646' : colors.main,
  flexShrink: 0,
  fontSize: '0.675rem',
  [s.md_only]: {
    fontSize: '0.5rem',
    padding: '0.5rem 0.5rem',
  },
  [s.xs]: {
    fontSize: 9,
    padding: '0.5rem 0rem',
  },
  '&:hover': {
    filter: sold ? 'brightness(0.5)' : 'brightness(1.5)',
  },
})

const sBooked = (isBooked) => ({
  ...sSold(false),
  backgroundColor: isBooked ? '#ff9900' : colors.main,
})

export default Table
